<template>
  <q-dialog v-model="showMacros" persistent>
    <q-card>
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Macros Para Criativos </q-toolbar-title>
        <q-btn icon="close" flat round dense @click="$emit('closemacro')" />
      </q-toolbar>

      <q-separator />

      <q-card-section style="max-height: 50vh" class="scroll">
        <p>São marcadores de posição, que dizem para a V3 em qual parte da URL serão inseridos os dados desejados.</p>
        <p>Seguem abaixo as macros disponíveis para incluir nas URLs de criativos da V3.</p>
      </q-card-section>

      <q-card-section class="items-center">
        <div class="q-pa-md">
          <q-markup-table>
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center">Macro</th>
                <th class="text-left">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[id_click]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[id_click]</td>
                <td class="text-left">ID do click da V3</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[canal_id]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[canal_id]</td>
                <td class="text-left">ID do canal</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[ld_sitename]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[ld_sitename]</td>
                <td class="text-left">Nome do canal</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[ld_aff_id]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[ld_aff_id]</td>
                <td class="text-left">ID do afiliado</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[ld_ban_id]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[ld_ban_id]</td>
                <td class="text-left">ID do criativo</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[ld_aff_xtra]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[ld_aff_xtra]</td>
                <td class="text-left">Info xtra1 do afiliado</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[ld_aff_token]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[ld_aff_token]</td>
                <td class="text-left">Info xtra2 do afiliado</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[cj_click]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[cj_click]</td>
                <td class="text-left">ID do click da CJ</td>
              </tr>
              <tr>
                <td class="text-right">
                  <q-btn
                    no-caps
                    flat
                    round
                    unelevated
                    icon="mdi-content-copy"
                    color="primary"
                    dense
                    @click="copyData('[ld_cjid]')"
                  >
                    <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                      Copiar Macro
                    </q-tooltip>
                  </q-btn>
                </td>
                <td class="text-center">[ld_cjid]</td>
                <td class="text-left">ID do canal na CJ</td>
              </tr>
            </tbody>
          </q-markup-table>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'macroListCreative',
  data() {
    return {
      showMacros: false
    };
  },
  watch: {
    showM(value) {
      this.showMacros = value;
    }
  },
  props: {
    showM: {
      type: [Boolean],
      default: false
    }
  }
};
</script>
