<template>
  <q-page padding>
    <header-creative />
    <list-creative />
    <edit-creative :id="idCreative" />
    <tag-creative :id="idCreativeTag" />
  </q-page>
</template>

<script>
import EditCreative from '../../components/creative/EditCreative.vue';
import HeaderCreative from '../../components/creative/HeaderCreative.vue';
import ListCreative from '../../components/creative/ListCreative.vue';
import TagCreative from '../../components/creative/TagCreative.vue';
import CreativeMixin from '../../mixins/CreativeMixin';
import CreativeService from '../../services/CreativesService';

export default {
  name: 'PageCreative',
  data() {
    return {
      dialog: false
    };
  },
  mixins: [CreativeMixin, CreativeService],
  props: {
    idCreative: {
      type: [Number, String],
      default: null
    },
    idCreativeTag: {
      type: [Number, String],
      default: null
    }
  },
  components: {
    ListCreative,
    HeaderCreative,
    EditCreative,
    TagCreative
  }
};
</script>
