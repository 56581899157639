<template>
  <q-dialog v-model="dialog" persistent>
    <div>
      <q-form @submit="onSave">
        <q-card flat>
          <q-toolbar class="bg-primary text-white">
            <q-toolbar-title> Editar Criativo</q-toolbar-title>
            <q-btn flat round dense icon="mdi-close" @click="onClose" />
          </q-toolbar>

          <q-card-section class="scroll" style="max-height: 70vh">
            <div class="text-h5">Campanha:{{ creative.campaign_name }}</div>
            <div class="row">
              <div class="col-8 q-pt-sm">
                Tipo de Criativo:
                {{ creative.creative_types_description }}
              </div>
              <div class="col-4 text-right">
                <q-toggle
                  v-model="form.status"
                  @input="onUpdateStatus"
                  :label="form.status ? 'Criativo ativo' : 'Criativo inativo'"
                />
              </div>
            </div>
            <div class="row q-col-gutter-md">
              <div v-if="creative.creative_types_name === 'image'" class="col-12">
                <q-card flat bordered class="q-mb-lg">
                  <q-btn
                    icon="mdi-pencil"
                    unelevated
                    class="q-ma-md absolute-top-right"
                    style="z-index: 1"
                    dense
                    rounded
                    color="primary"
                    @click="$refs.file.pickFiles()"
                  >
                  </q-btn>

                  <q-file
                    ref="file"
                    v-show="false"
                    v-model="fileImage"
                    accept="image/*"
                    outlined
                    @input="onChangeImage"
                  />

                  <q-card-section class="text-center">
                    <!-- <q-img
                            class="col-12"
                            v-if="!imageUrl"
                            :src="form.image_url"
                            style="width: 360px; height: 200px"
                            contain
                        /> -->

                    <q-img class="col-12" v-if="imageUrl" :src="imageUrl" style="width: 360px; height: 200px" contain />
                    <q-img class="col-12" v-else :src="form.image_url" style="width: 360px; height: 200px" contain />

                    <q-input
                      class="q-mt-md"
                      v-model="form.image_url"
                      outlined
                      type="url"
                      label="URL da Imagem"
                      @input="imageUrl = form.image_url"
                    />
                  </q-card-section>
                </q-card>
                <div class="col-12">
                  <q-input v-model="form.title" label="Titulo" outlined class="q-pb-md" />
                </div>

                <div class="col-12">
                  <q-input
                    v-model="form.destination_url"
                    type="text"
                    label="URL de redirecionamento"
                    outlined
                    lazy-rules
                    autogrow
                    :rules="[isRequired]"
                  >
                    <template v-slot:append>
                      <q-icon
                        name="mdi-help-circle-outline"
                        class="cursor-pointer"
                        size="1.3em"
                        color="primary"
                        @click="onShowMacros"
                      >
                        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                          Macros
                        </q-tooltip>
                      </q-icon>
                      <MacroListCreative :showM="showMacros" @closemacro="showMacros = false" />
                    </template>
                  </q-input>
                </div>

                <div class="col-12">
                  <q-select
                    v-model="form.id_size"
                    :options="bannerSize"
                    label="Tamanho do Banner"
                    outlined
                    option-label="size"
                    option-value="id"
                  />
                </div>
              </div>

              <div
                class="col-12"
                v-if="
                  creative.creative_types_name === 'hyperlink' ||
                  creative.creative_types_name === 'coupon' ||
                  creative.creative_types_name === 'deal'
                "
              >
                <q-input v-model="form.title" type="text" label="Titulo" outlined lazy-rules :rules="[isRequired]" />
              </div>
              <div
                class="col-12"
                v-if="
                  creative.creative_types_name === 'hyperlink' ||
                  creative.creative_types_name === 'coupon' ||
                  creative.creative_types_name === 'deal'
                "
              >
                <q-input
                  v-model="form.description"
                  type="text"
                  label="Texto"
                  outlined
                  lazy-rules
                  :rules="[isRequired]"
                />
              </div>
              <div class="col-12 text-right" v-if="creative.creative_types_name === 'emailmkt'">
                <q-input v-model="form.html" type="textarea" label="Html" outlined lazy-rules :rules="[isRequired]">
                  <template v-slot:append></template>
                </q-input>
                <q-btn label="Macros" color="primary" class="q-mt-sm q-mr-lg" no-caps unelevated @click="onShowMacros">
                  <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                    Macros
                  </q-tooltip>
                  <MacroListCreative :showM="showMacros" @closemacro="showMacros = false" />
                </q-btn>

                <q-btn
                  color="primary"
                  class="q-mt-sm q-mr-lg"
                  icon="fullscreen"
                  @click="openFullScreen"
                  no-caps
                  unelevated
                />

                <q-btn color="primary" class="q-mt-sm" label="Visualizar" @click="openEmailTab" no-caps unelevated />
              </div>
              <div class="col-12" v-if="creative.creative_types_name === 'emailmkt'">
                <q-input v-model="form.subject" type="text" label="Assunto" outlined lazy-rules :rules="[isRequired]" />
              </div>
              <div class="col-12" v-if="creative.creative_types_name === 'emailmkt'">
                <q-input v-model="form.sender" type="text" label="Sender" outlined lazy-rules :rules="[isRequired]" />
              </div>
              <div class="col-12">
                <q-input
                  v-model="form.code"
                  type="text"
                  label="Código"
                  outlined
                  v-if="creative.creative_types_name === 'coupon'"
                  lazy-rules
                  :rules="[isRequired]"
                />
              </div>
              <div
                class="col-12"
                v-if="
                  creative.creative_types_name === 'hyperlink' ||
                  creative.creative_types_name === 'coupon' ||
                  creative.creative_types_name === 'deal'
                "
              >
                <q-input
                  v-model="form.destination_url"
                  type="url"
                  label="Url do redirecionamento"
                  outlined
                  lazy-rules
                  autogrow
                  :rules="[isRequired]"
                >
                  <template v-slot:append>
                    <q-icon
                      name="mdi-help-circle-outline"
                      class="cursor-pointer"
                      size="1.3em"
                      color="primary"
                      @click="onShowMacros"
                    >
                      <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                        Macros
                      </q-tooltip>
                      <MacroListCreative :showM="showMacros" @closemacro="showMacros = false" />
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <date-input
                  :v-model="form.activation_date"
                  outlined
                  minimal
                  clearable
                  label="Data de Ativação"
                  :rules="[isRequired, isValidDate]"
                  @update="form.activation_date = $event"
                />
              </div>
              <div class="col-12">
                <time-input
                  v-model="form.activation_time"
                  :rules="[isValidTime]"
                  label="Hora de Ativação"
                  @timeUpdate="(val) => (form.activation_time = val)"
                  :currentTime="$moment(form.activation_date).locale($t('format_date')).format('LT')"
                />
              </div>
              <div class="col-12">
                <date-input
                  :v-model="form.expiration_date"
                  outlined
                  minimal
                  clearable
                  label="Data de expiração"
                  :rules="[isValidDate]"
                  @update="form.expiration_date = $event"
                />
              </div>

              <div class="col-12">
                <time-input
                  v-model="form.expiration_time"
                  :rules="form.expiration_time != 'Invalid date' ? [isValidTime] : ''"
                  label="Hora de expiração"
                  @timeUpdate="(val) => (form.expiration_time = val)"
                  :currentTime="$moment(form.expiration_date).locale($t('format_date')).format('LT')"
                />
              </div>

              <div class="col-12">
                <q-select
                  v-model="form.category"
                  :options="categories"
                  label="Categoria"
                  outlined
                  option-label="name"
                  option-value="id"
                />
              </div>
              <div class="col-12">
                <q-select
                  v-model="form.offer"
                  :options="offers"
                  label="Oferta/Sazonalidade"
                  outlined
                  option-label="name"
                  option-value="id"
                />
              </div>

              <div class="col-12">
                <q-select
                  v-model="form.site"
                  outlined
                  multiple
                  use-chips
                  use-input
                  input-debounce="200"
                  label="Canal"
                  @filter="filterSites"
                  :options="sitesFiltered"
                  option-label="name"
                  option-value="id"
                  clearable
                  clear-icon="mdi-close"
                />
              </div>
            </div>
          </q-card-section>
          <q-separator spaced inset />
          <q-card-actions align="right">
            <q-btn unelevated outline no-caps label="Excluir" class="q-px-md" color="negative" @click="onDelete" />
            <q-btn unelevated outline no-caps label="Cancelar" class="q-px-md" color="primary" @click="onClose" />
            <q-btn unelevated class="q-px-md" no-caps label="Salvar" color="primary" type="submit" />
          </q-card-actions>
        </q-card>

        <q-dialog
          v-model="dialogFullScreen"
          persistent
          :maximized="maximizedToggle"
          transition-show="slide-up"
          transition-hide="slide-down"
        >
          <q-card>
            <q-bar>
              <q-space />

              <q-btn
                v-if="maximizedToggle"
                dense
                flat
                icon="minimize"
                @click="maximizedToggle = false"
                :disable="!maximizedToggle"
              >
                <q-tooltip content-class="bg-white text-primary">Minimize</q-tooltip>
              </q-btn>
              <q-btn
                v-if="!maximizedToggle"
                dense
                flat
                icon="crop_square"
                @click="maximizedToggle = true"
                :disable="maximizedToggle"
              >
                <q-tooltip content-class="bg-white text-primary">Maximize</q-tooltip>
              </q-btn>
              <q-btn dense flat icon="close" v-close-popup>
                <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
              </q-btn>
            </q-bar>

            <q-card-section>
              <q-input v-model="form.html" type="textarea" label="Html" outlined>
                <template v-slot:append></template>
              </q-input>
            </q-card-section>
          </q-card>
        </q-dialog>
      </q-form>
    </div>
  </q-dialog>
</template>

<script>
import CreativeMixin from '@/mixins/CreativeMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import CreativesService from '../../services/CreativesService';
import ChannelService from '../../services/ChannelService';
import MacroListCreative from '../creative/formCreativeTypes/MacroListCreative.vue';
import TimeInput from '../../widgets/inputsGlobal/TimeInput.vue';

export default {
  components: { DateInput, MacroListCreative, TimeInput },
  mixins: [CreativesService, CreativeMixin, ChannelService],
  data() {
    return {
      showMacros: false,
      dialog: false,
      creative: {
        campaign_name: null,
        id: null
      },
      categories: [],
      bannerSize: [],
      sitesList: [],
      sitesFiltered: [],
      offers: [],
      imageUrl: null,
      fileImage: null,
      form: {
        site: [],
        id_site: null,
        image_url: null,
        id_size: null,
        id_banner_size: null,
        html: null,
        subject: null,
        offer: null,
        sender: null,
        category: null,
        id_campaign: null,
        id_type: null,
        code: null,
        title: null,
        description: null,
        destination_url: null,
        id_offer: null,
        id_category: null,
        activation_date: null,
        activation_time: null,
        expiration_date: null,
        expiration_time: null,
        status: false
      },
      newStatus: null,
      prevStatus: null,
      prevActivationDate: null,
      dialogFullScreen: false,
      maximizedToggle: true
    };
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },
  methods: {
    onShowMacros() {
      this.showMacros = true;
    },
    onChangeImage(value) {
      console.log('value', value);
      this.form.image_url = null;
      this.imageUrl = URL.createObjectURL(value);
    },

    async getSites() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getListChannels();
        if (status === 200) this.sitesList = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterSites(val, update) {
      update(this.defaultFilter(val, 'sitesFiltered', this.sitesList, 'name'));
    },

    onDelete() {
      this.showDialog(
        'Desativar Criativo',
        'Tem certeza que deseja desativar esse criativo? ' +
          '<br> Para ativar novamente é necessário entrar em contato com seu gerente',
        () => {
          this.onOk(this.id);
        },
        () => {
          this.onCancel;
        }
      );
    },

    onCancel() {
      this.successNotify('Ação cancelada');
    },

    async onOk(creativelId) {
      this.onLoading(true);

      try {
        await this.editStatus(2, creativelId);
        this.successNotify('Criativo deletado com sucesso');

        this.dialog = false;
        this.$router.push({ name: 'CreativeList' });
        this.getCreative();
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    onClose() {
      this.clearAll();
      this.ActionSetCreativeSelect('');
      this.dialog = false;
      this.$router.push({ name: 'CreativeList' });
    },

    onSave() {
      this.form.id_category = this.form.category?.id || '';
      this.form.id_offer = this.form.offer?.id || '';
      this.form.id_size = this.form.id_size?.id || '';

      if (this.form.site.length > 0) this.form.id_site = this.form.site.map((element) => element.id).join(',');

      if (!this.form.activation_time || this.form.activation_time == 'Invalid date')
        this.form.activation_time = '00:00';

      if (!this.form.expiration_time || this.form.expiration_time == 'Invalid date')
        this.form.expiration_time = '00:00';

      if (this.form.activation_date) {
        const formattedDate = this.$moment(this.form.activation_date)
          .format('YYYY-MM-DD')
          .concat(` ${this.form.activation_time}:00`);
        this.form.activation_date = formattedDate;
      }

      if (this.form.expiration_date) {
        const formattedDate = this.$moment(this.form.expiration_date)
          .format('YYYY-MM-DD')
          .concat(` ${this.form.expiration_time}:00`);
        this.form.expiration_date = formattedDate;
      }

      this.update(this.form);
    },

    onUpdateStatus() {
      this.newStatus = !this.form.status ? 3 : 1;
      let newDate = this.$moment().format('YYYY-MM-DD');
      if (this.newStatus == 1) this.form.activation_date = newDate;
      if (this.newStatus == 3) this.form.activation_date = this.prevActivationDate;
    },

    async updateStatus(status) {
      try {
        await this.editStatus(status, this.id);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    clearAll() {
      for (const key in this.form) {
        if (key == 'site') this.form[key] = [];
        else if (key == 'status') this.form[key] = false;
        else this.form[key] = null;
      }
    },

    async update(data) {
      this.onLoading(true);
      !data.status ? (data.status = 3) : (data.status = 1);

      try {
        if (this.fileImage) {
          const { data: image, status } = await this.upload(this.form.id_campaign, this.fileImage);
          if (status == 200 || status == 201) data.image_url = image?.image_upload.image_url;
        }
        delete data.activation_time;
        delete data.expiration_time;

        if (this.newStatus !== null && this.newStatus !== this.prevStatus) {
          this.updateStatus(this.newStatus);
          this.newStatus = null;
        }

        const { status } = await this.editCreative(this.id, data);
        if (status == 200 || status == 201) {
          this.successNotify('Criativo atualizado com sucesso');
          this.clearAll();
          this.$router.push({ name: 'CreativeList' });
          this.getCreative();
          this.dialog = false;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        this.getCreativeById(this.id);
      } finally {
        this.onLoading(false);
      }
    },

    setCreative(data) {
      if (data.title) this.form.title = data.title;
      if (data.description) this.form.description = data.description;
      if (data.code) this.form.code = data.code;
      if (data.subject) this.form.subject = data.subject;
      if (data.sender) this.form.sender = data.sender;
      if (data.html) this.form.html = data.html;
      if (data.image_url) this.form.image_url = data.image_url;
      if (data.destination_url) this.form.destination_url = data.destination_url;
      if (data.activation_date) this.form.activation_date = this.form.activation_time = data.activation_date;
      if (data.expiration_date) this.form.expiration_date = this.form.expiration_time = data.expiration_date;
      if (data.id_category) this.form.category = this.categories.find((item) => item.id == data.id_category);
      if (data.id_offer) this.form.offer = this.offers.find((item) => item.id == data.id_offer);

      if (data.banner_sizes_size)
        this.form.id_size = this.bannerSize.find((item) => item.size == data.banner_sizes_size);

      if (data.id_site)
        data.id_site.split(',').forEach((element) => {
          this.form.site.push(this.sitesList.find((item) => item.id == element));
        });

      this.form.id_type = data.id_type;
      this.form.id_campaign = data.id_campaign;
      data.status === 1 ? (this.form.status = true) : (this.form.status = false);
    },

    async getCreativeById(id) {
      try {
        const { data, status } = await this.getCreativeId(id);
        if (status == 200) {
          this.creative = data.creatives;
          this.setCreative(data.creatives);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getBannerSize() {
      try {
        const { data, status } = await this.$http.get('redirect?app=CREATIVE&path=/api/get/banner_size');
        if (status == 200) {
          this.bannerSize = data;
        }
      } catch (error) {
        //console.log(error);
      }
    },

    async getCategories() {
      try {
        const { data, status } = await this.$http.get('redirect?app=CREATIVE&path=/api/get/category');
        if (status == 200) this.categories = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getAffers() {
      try {
        const { data, status } = await this.$http.get('redirect?app=CREATIVE&path=/api/get/offer');
        if (status == 200) this.offers = data;
      } catch (error) {
        console.log(error);
      }
    },

    openEmailTab() {
      let wnd = window.open('about:blank', '_blank');
      wnd.document.write(this.form.html);
    },

    openFullScreen() {
      this.dialogFullScreen = !this.dialogFullScreen;
    }
  },
  watch: {
    async id() {
      if (this.id !== null) {
        this.onLoading(true);
        await this.getCategories();
        await this.getBannerSize();
        await this.getAffers();
        await this.getSites();
        await this.getCreativeById(this.id);
        this.dialog = true;
        this.onLoading(false);
      }
      this.prevStatus = !this.form.status ? 3 : 1;
      this.prevActivationDate = this.form.activation_date;
    }
  },
  async created() {
    if (this.id !== null) {
      this.onLoading(true);

      await this.getSites();
      await this.getCategories();
      await this.getBannerSize();
      await this.getAffers();
      await this.getCreativeById(this.id);
      this.dialog = true;

      this.onLoading(false);
    }

    this.imageUrl = this.form.image_url;
  }
};
</script>

<style></style>
