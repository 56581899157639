<template>
  <q-input v-model="time" outlined :label="label" clearable mask="time" :rules="rules">
    <template v-slot:prepend>
      <q-icon name="access_time" class="cursor-pointer" color="primary" dense flat unelevated>
        <q-popup-proxy transition-show="scale" transition-hide="scale">
          <q-time v-model="time">
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Fechar" color="negative" no-caps unelevated />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
export default {
  name: 'TimeInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    currentTime: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      time: null
    };
  },

  created() {
    this.time = this.currentTime;
  },

  methods: {
    emitTime() {
      this.$emit('timeUpdate', this.time);
    }
  },

  watch: {
    time: 'emitTime'
  }
};
</script>
